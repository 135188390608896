<template>
  <div class="rating-type">
    <div class="rating-containter" :class="{'handleChecked': isConstantSumCompleted}">

      <p class="constant-sum-indicator" v-if="component.type == 'constant_sum'">{{ messages.assign }} <b><span v-if="sumVals > 0">{{sumVals}} /</span> {{component.properties.total_sum.total}}</b> {{component.properties.total_sum.unit}}</p>

        <div class="subquestion-container" v-for="(question, key) in questionsList" :key="key">      

          <h3 v-if="showQuestions">{{ question.question }}</h3>

          <div class="form-numbers rating-container" v-if="component.properties.type == 'numbers'">
            <div class="rating-block numbers">
              <template v-for="key2 in numberArray">
                <input type="radio" 
                  :id="'ratingType-' + component.id + '-' + key2[0] + '-' + key" 
                  :name="'ratingType-' + component.id + '-' + key" 
                  :value="key2[0]" 
                  @change="updateData(key, key2[0])" 
                  tabindex="-1" 
                  :key="'ratingType-' + component.id + '-' + key2[0] + '-' + key">
                <label :for="'ratingType-' + component.id + '-' + key2[0] + '-' + key" 
                       ref="ratingItem" 
                       class="tabable" 
                       tabindex="-1" 
                       :key="'ratingType-' + component.id + '-' + key2[0] + '-' + key + '-label'">
                  <div>{{ key2[0] }}</div>
                </label>
              </template>

            </div>
          </div>
          <div class="form-slider" :class="{ 'completed': selectedValues[key]}" v-else>
            <vue-slider v-bind="sliderOptions[key]" v-model="values[key]" @change="updateData(key)" @drag-start="dragStart(key)" @drag-end="updateData(key)" :process="processLine" />
          </div>

          <input v-if="false" type="number" v-model="values[key]" @input="updateData(key)">

          <div class="question-labels" v-if="!component.properties.hide_questions_labels">
            <div>
              {{question.sub_labels.left}}
            </div>
            <div>
             {{question.sub_labels.right}}
            </div>
          </div>
          
        </div>
    </div>
    <small class="error" v-show="component.required_show_label">{{ messages.answer_is_required }}</small>
  </div>
</template>

<script>
import Vue from 'vue'
import { store } from '../store'
import SvgIcon from './svg-icon';
import VueSlider from 'vue-slider-component'
import { default as shakeElement } from '../../components/builder/mixins/shakeElement'

export default {
  props: ['component'],
  mixins: [shakeElement],
  data: function () {
    return {
      store: store,
      messages: window.form.messages,
      values: [],
      selectedValues: [],
      dotOptionsMax: 100,
      sliderOptions: []
    }
  },
  components: {
    'svg-icon': SvgIcon,
     VueSlider
  },
  created: function () {
    if (this.component.properties.required) {
      Vue.set(this.component, 'required_show_label', 0)
    }

    if (this.component.properties.randomize) {
      this.questionsList = _.shuffle(this.component.questions)
    } else {
      this.questionsList = this.component.questions
    }    


    // prepear sliders options and default values
    this.marksDivider = Math.round(this.component.properties.range.end / 5)
    let min = this.component.properties.range.start
    let max = this.component.properties.range.end
    this.dotOptionsMax = this.component.properties.range.end
    for (let i = 0; i < this.component.questions.length; i++) {
      Vue.set(this.sliderOptions, i, {
          dotSize: 28,
          width: 'auto',
          height: 10,
          contained: false,
          direction: 'ltr',
          data: null,
          dataLabel: 'label',
          dataValue: 'value',
          min: min,
          max: max,
          interval: 1,
          disabled: false,
          clickable: true,
          duration: 0.5,
          adsorb: false,
          lazy: false,
          tooltip: 'none',
          tooltipPlacement: 'top',
          tooltipFormatter: void 0,
          useKeyboard: false,
          keydownHook: null,
          dragOnClick: false,
          enableCross: true,
          fixed: false,
          marks: val => val % this.marksDivider === 0,
          dotOptions: { max: this.dotOptionsMax },
        })
        if (this.component.properties.slider.hide_segments) {
          Vue.set(this.sliderOptions[i], 'marks', false)
        }
        if (this.component.properties.slider.show_value) {
          Vue.set(this.sliderOptions[i], 'tooltip', 'active')
        }

        Vue.set(this.values, i, this.sliderDefaultValue)
    }

    let self = this
    Event.$on('nextButtonClick', function () { 
      if (self.component.required_show_label) {
        self.shakeSliderDots()
      }    
    })

  },
  watch: {
    'component.required_show_label': function (val, oldval) {
      if (this.component.required_show_label) {
        this.shakeSliderDots()
      }
    }
  },
  computed: {
    numberArray: function() {
      return Array.from({length: this.component.properties.range.end}, (v, k) => [k + 1, '']).reverse()
    },
    processLine: function () {
      if (this.component.type == 'semantic_differential') {
        return dotsPos => [[50, dotsPos[0]]]
      } else {
        return true
      }
    },
    sumVals: function () {
    	let sum = 0
    	for (let i = 0; i < this.values.length; i++) {
        if (this.values[i]) {
      	  sum = sum + this.values[i]
        }
      }
      return sum
    },
    isConstantSumCompleted: function () {
      if (this.component.type == 'constant_sum') {
        if (this.sumVals == this.component.properties.total_sum.total) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    sliderDefaultValue: function () {
        if (this.component.properties.slider.start_position == 'center') {
          return (Number(Number(this.component.properties.range.end) - Number(this.component.properties.range.start)) / 2) + this.component.properties.range.start
        } else {
          return this.component.properties.range.start
        }
      },
    showQuestions: function () {
        if (this.component.properties.hide_questions) {
          return false
        }
        if (this.component.questions.length < 2) {
          return false
        }
        return true
      },
  },
  methods: {
    shakeSliderDots: function () {
      var sliders = document.querySelectorAll(".form-slider")
      for (var i = 0; i < sliders.length; i++) {
        if (!sliders[i].classList.contains('completed')) {
          let slider = sliders[i].querySelectorAll(".vue-slider-dot-handle")[0]
          this.shakeUiElement(slider)
        }
      }
    },
    dragStart: function (key) {
      if (this.component.type != 'constant_sum') {
        this.updateData(key)
      }
    },
    updateData: function (key, val) {

      if (this.component.type == 'constant_sum') {

        // check 
        let range_end = this.component.properties.range.end
        let total_sum = this.component.properties.total_sum.total
        let sum_vals = this.sumVals

        let slideRange =  total_sum - sum_vals;
        if (slideRange > range_end) {
          slideRange = range_end
        }
        slideRange += this.values[key]
        if (slideRange > range_end) {
          slideRange = range_end
        }
        this.dotOptionsMax = slideRange


        Vue.set(this.sliderOptions[key].dotOptions, 'max', slideRange )  
   
        for (let i = 0; i < this.sliderOptions.length; i++) {
      		let maxHelper = total_sum - sum_vals
          maxHelper += this.values[i]
          if(maxHelper > range_end){
						maxHelper = range_end;
					}
          Vue.set(this.sliderOptions[i].dotOptions, 'max', maxHelper)
        }
      }
      
      let value
      if (this.component.properties.type == 'numbers') {
        value = val
      } else {
        value = this.values[key]
      }

  
      let removeIfExist = _.findIndex(this.selectedValues, {'id': this.questionsList[key].id})
      if (removeIfExist > -1) {
        Vue.delete(this.selectedValues, removeIfExist)
      }

      this.selectedValues.push({
        'id': this.questionsList[key].id,
        'question': this.questionsList[key].question,
        'value': value
      })

      Vue.set(this.store.answers, this.component.ref, {
        "component": {
            "id": this.component.id,
            "type": this.component.type
        },
        "type": "numbers_scale",
        "numbers_scale": this.selectedValues
      })

      if (this.component.validations.required) {
        let error = false

        if (this.component.type == 'constant_sum') {
          if (this.component.properties.total_sum.total !== this.sumVals) {
            error = true
          }
        } else {
          let count = 0
          for (let i = 0; i < this.component.questions.length; i++) {
            if (this.selectedValues[i]) {
              count++
            }
          }
          if (count !== this.component.questions.length) {
            error = true
          }
        }

        if (error) {
          Vue.set(this.component, 'is_valid', false)
        } else {
          Vue.set(this.component, 'is_valid', true)
          Vue.set(this.component, 'required_show_label', false)
        }

        
      }

      Event.$emit('verifyPage')
    }
  }
}
</script>

<style lang="scss">
  .subquestion-container {
    padding: 20px 0 10px 0;
    display: block;
  }
  .form-slider {
    margin-top: 30px;
    padding: 0 10px;
    &:not(.completed) {
      opacity: .7;
    }
  }
  .question-labels {
    padding: 0px 0 0 0;
    display: flex;
    div {
      flex-grow: 1;
      flex-basis: 0;
      &:last-of-type {
        text-align: right;
      }
    }
  }
  .handleChecked {
    .vue-slider-dot-handle {
      text-align: center;
      &:before {
        font-family: "responslycon";
        font-style: normal;
        font-weight: normal;
        display: inline-block;
        text-decoration: inherit;
        
        font-size: 16px;
        line-height: 28px;
        text-align: center;
        content: '\e810'; 
        color: white;
      }
    }
  }

</style>