<template src="./theForm.html"></template>
<script>
import Vue from 'vue'
import lodash from 'lodash'
import axios from 'axios'
import sassStyles from './../assets/scss/form/style.scss'
import mediabox from './components/mediabox.vue'
import componentSelector from './components/componentSelector.vue'
import validationMixin from './mixins/validationMixin'
import pipingMixin from './mixins/pipingMixin'
import logicMixin from './mixins/logicMixin'
import { store } from './store'
var VueScrollTo = require('vue-scrollto')
Vue.use(VueScrollTo)
import logo from '../globalComponents/logo.vue'
import timeTranslation from './mixins/timeTranslation'

import vSelect from 'vue-select'
vSelect.props.components.default = () => ({
  Deselect: {
    render: createElement => createElement('span', ''),
  },
  OpenIndicator: {
    render: createElement => createElement('span', ''),
  },
})

export default {
  name: 'theform',
  mixins: [validationMixin, pipingMixin, logicMixin],
  components: {
    componentSelector,
    mediabox,
    logo,
    vSelect
  },
  data: function () {
    return {
      urls: window.urls,
      store: store,
      form: window.form,
      plan: window.plan,
      languages: window.avaliable_translations,
      formDisabled: window.form_disabled,
      formDisabledMsg: '',
      indicatorOverlay: false,
      currentPage: 'start',
      btnNextLabel: window.form.messages.next,
      pageErrors: [],
      showErrorMsg: false,
      oneClickSkip: false,
      visitedPages: [],
      logicJumpTo: false,
      viewportWidth: 0,
      respondentPassword: '',
      timeCounter: false,
      timeCounterTxt: '',
      disableUserAction: false,
      endPageRef: '',
      redirectAfterSubmitUrl: false,
      isSubmitted: false,
      isEmbeded: false,
      isPreview: false,
      appEnv: process.env,
      responseToken: null
    }
  },
  watch: {
    currentPage: function (currentPage) {
      let self = this
      this.pageErrors = []
      this.showErrorMsg = false

      if (this.currentPage < (this.form.components.length - 1)) {
            this.btnNextLabel = window.form.messages.next
      } else {
            this.btnNextLabel = window.form.messages.send
      }

      // visited pages history
      if (_.indexOf(this.visitedPages, this.currentPage) == -1) {
            this.visitedPages.push(this.currentPage)
      }

      this.calculateVariables()

      if (this.currentPage >= 0 && this.currentPage < this.form.components.length) {
        let isEmbeded = this.isEmbeded
        let currentPage = this.currentPage
        let welcomePage = this.welcomePage
        setTimeout(function(){
          window.tabbables = document.getElementById("active-container").querySelectorAll(".tabable");
          window.currentTabIndex = -1;


          var cols = document.getElementsByClassName('tabable');
          for(let i = 0; i < cols.length; i++) {
            cols[i].tabIndex = -1;
          }

          setTimeout(function(){
            if (window.tabbables[0] && !(isEmbeded && currentPage == 0 && !welcomePage)) {

              for (let i = 1; i < window.tabbables.length; i++) {
                window.tabbables[i].tabIndex = i
              }
              if (window.tabbables[0].nodeName == 'INPUT' || window.tabbables[0].nodeName == 'TEXTAREA') {
                if (self.currentPageComponents.hasOwnProperty('type') && self.currentPageComponents.type !== 'group') {
                  window.tabbables[0].focus()
                }
              }
            }
          }, 200)
          
        }, 100)
      }

      window.currentPage = currentPage + 1
    }
  },
  computed: {
    currentPageComponents: function () {
      return this.form.components[this.currentPage]
    },
    endPage: function () {
      let wpage
      if (this.endPageRef) {
        let epage = _.find(this.form.end_pages, {'ref': this.endPageRef})
        if (epage) {
          wpage = epage
        } else {
          wpage = this.form.end_pages[0]
        }
      } else {
        wpage = this.form.end_pages[0]
      }
      wpage.title = wpage.title.replace(/(<([^>]+)>)/gi, "")
      wpage.properties.description = wpage.properties.description.replace(/(<([^>]+)>)/gi, "")

      return wpage
    },
    progressStyle: function () {
        let percentage = ( (Number(this.currentPage) + 1) / (Number(this.form.components.length)) ) * Number(100);
        return 'width: ' + percentage + '%;';
    },
    progressPercentage: function () {
      return (this.currentPage + 1) * 100 / this.form.components.length
    },
    welcomePage: function () {
      if (this.form.welcome_pages.length > 0) {
        let wpage = this.form.welcome_pages[0]
        wpage.title = wpage.title.replace(/(<([^>]+)>)/gi, "")
        wpage.properties.description = wpage.properties.description.replace(/(<([^>]+)>)/gi, "")
        return wpage
      } else {
        if (this.form.settings.password_protection) {
          let title = form.settings.name.replace(/(<([^>]+)>)/gi, "")
          return {
            title: title,
            properties: {
              align: 'center',
              button_text: window.form.messages.unlock
            }
          }
        } else {
          return false
        }
      }
    },
    isNotOneClick: function () {
      if (this.form.settings.disable_oneclick) {
        return true
      }
      if (this.currentPage >= 0 && this.currentPage < this.form.components.length) {
        let component = this.form.components[this.currentPage]
        return component.type == 'group' || 
              component.type == 'statment' || 
              component.type == 'file_upload' || 
              component.type == 'text' || 
              component.type == 'number' ||   
              component.type == 'date' || 
              component.type == 'website' ||  
              component.type == 'mail' ||  
              component.type == 'phone_number' ||
              (component.type == 'multiple_choice' && (component.properties.allow_other_choice || component.properties.allow_multiple_selection)) ||
              (component.type == 'picture_choice' && component.properties.allow_multiple_selection) || 
              component.type == 'slider' || 
              component.type == 'constant_sum' || 
              component.type == 'matrix' || 
              component.type == 'ranking' || 
              component.type == 'semantic_differential' || 
              component.type == 'numbers_scale'
      } else {
        return false
      }
    },
    componentsAlignment: function () {
      if (this.form.settings.components_alignment) {
        return 'components-alignment-' + this.form.settings.components_alignment
      } else {
        return ''
      }
    },
    startLabelText: function () {
      if (this.welcomePage && this.welcomePage.properties.button_text.length > 0) {
        return this.welcomePage.properties.button_text
      } else {
        return 'Start'
      }
    },
    estimatedTime () {
      let time = 0
      for (let i = 0; i < this.form.components.length; i++) {
        time = time + 15
        if (this.form.components[i].type == 'group') {
          for (let j = 0; j < this.form.components[i].components.length; j++) {
            time = time + 10
          }
        }
      }
      return time
    },
    anonymousSurveyLink () {
      if (window.anonymous_survey_link) {
        return true
      }
      return false
    }
  },
  mounted: function () {
    this.$nextTick(function() {
        window.addEventListener('resize', this.getWindowWidth)
        this.getWindowWidth()
    })
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.getWindowWidth)
  },
  created: function () {
    if ( window.preview === 1)
      this.isPreview = true

    let self = this
    document.getElementById('indicator').style.display = 'none'

    // before close
    const urlParams = new URLSearchParams(window.location.search);
    if (!urlParams.get('widget') && !urlParams.get('embeded') && !urlParams.get('dev') && !urlParams.get('preview')) {
      window.addEventListener('beforeunload', this.onBeforeUnload)
    } else {
      if (urlParams.get('widget') || urlParams.get('embeded')) {
        this.isEmbeded = true
      }
    }
    
    if (this.form.components.length == 0) {
      this.formDisabled = true;
    }

    if (this.formDisabled) {

      this.formDisabledMsg = window.user_msg
      this.currentPage = this.form.components.length + 1
      setTimeout(function(){
            self.indicatorOverlay = true
      }, 100)

    } else {


      if (this.form.settings.randomize) {
        let tempComponents = this.form.components
        switch (this.form.settings.randomize) {
          case 'lock_first':
              let first = tempComponents[0]
              tempComponents.shift()
              tempComponents = _.shuffle(tempComponents)
              tempComponents.unshift(first)
              Vue.set(this.form, 'components', tempComponents)
            break
          case 'lock_last':
              let last = tempComponents[tempComponents.length - 1]
              tempComponents.pop()
              tempComponents = _.shuffle(tempComponents)
              tempComponents.push(last)
              Vue.set(this.form, 'components', tempComponents)
            break
          case 'lock_custom':
            if (this.form.settings.lock_custom_pages && this.form.settings.lock_custom_pages.length > 0) {
              let lockedPages = this.form.settings.lock_custom_pages;
              let lockedComponents = [];
              let shuffleComponents = [];
              
              tempComponents.forEach((component) => {
                if (lockedPages.includes(component.ref)) {
                  lockedComponents.push(component);
                } else {
                  shuffleComponents.push(component);
                }
              });
              
              shuffleComponents = _.shuffle(shuffleComponents);
              
              let newComponents = [];
              let shuffleIndex = 0;
              
              tempComponents.forEach((component) => {
                if (lockedPages.includes(component.ref)) {
                  newComponents.push(lockedComponents.shift());
                } else {
                  newComponents.push(shuffleComponents[shuffleIndex]);
                  shuffleIndex++;
                }
              });
              
              Vue.set(this.form, 'components', newComponents);
            } else {
              Vue.set(this.form, 'components', _.shuffle(tempComponents));
            }
            break
          default:
              tempComponents = _.shuffle(tempComponents)
              Vue.set(this.form, 'components', tempComponents)
        }
      }   


      this.visitedPages = [this.currentPage]
      if (!this.welcomePage) {
        if (!this.form.settings.password_protection) {
          this.currentPage = 0
          Vue.delete(this.visitedPages, 0)
        }
      }

      // get url for prepopulare and hidden vars
      let url = new URL(window.location.href)

      // prepopulate
      let prePopulateValue = url.searchParams.get('_rate')
      if (prePopulateValue && _.inRange(prePopulateValue, 0, 10) && !this.welcomePage) { // is _rate= and range from 0-10 and welcomepage is disabled
        let component = this.form.components[0]
        if ((component.type == 'rating' || component.type == 'customer_sat' || component.type == 'opinion_scale') && this.validateNumber(prePopulateValue)) {
            Vue.set(this.store.answers, component.ref, {
                  "component": {
                      "id": component.id,
                      "type": component.type
                  },
                  "type": "number",
                  "number": Number(prePopulateValue)
              })
              this.visitedPages = [this.currentPage]
            this.goToNextPage()
        }
      }

      // set hidden vars
      if (window.hidden_array) {
        Vue.set(this.store, 'hidden', window.hidden_array)

        // ab testing
        if (this.form.settings.enable_ab_testing) {
          if (!this.store.hidden.hasOwnProperty('ab_testing')) {
            if (this.form.settings.ab_testing_versions && this.form.settings.ab_testing_versions > 1) {
                Vue.set(this.store.hidden, 'ab_testing', {'name': 'ab_testing', 'value': Math.floor(Math.random() * this.form.settings.ab_testing_versions) + 1})
            }
          }
        }
      }

      // execute hidden logic
      let hiddenRules = _.find(this.form.logic, { 'type': 'hidden' })
      this.computeConditions(hiddenRules)
      if (this.logicJumpTo) {
        this.goToNextPage()
      }
      
      Event.$on('verifyPage', function () {      
        self.showNextButton()
      })

      Event.$on('verifyAndSkipPage', function () {

        let index = self.currentPage
        if (self.form.components[index] && self.form.components[index].type == 'group') {
          currentTabIndex = window.currentTabIndex + 1;
          if (window.tabbables[window.currentTabIndex]) {
            window.tabbables[window.currentTabIndex].focus()
          } else {
            self.verifyCurrentPage()
          }
        } else {
          self.verifyCurrentPage()
        }
      })

      // keyboard navigation
      setTimeout(function(){

          let active_container = document.getElementById("active-container")
          if (active_container) {
            window.tabbables = active_container.querySelectorAll(".tabable")
          }
        
          window.currentTabIndex = -1

          window.addEventListener('keydown', function(event) {  
            if (event.keyCode == 9) { // tab disabled
              event.preventDefault()
            }
          })
          window.addEventListener('keyup', function(event) {

            if (event.keyCode == 40) { // arrow down
              event.preventDefault();
              window.currentTabIndex = window.currentTabIndex + 1;
              if (window.tabbables[window.currentTabIndex]) {
                window.tabbables[window.currentTabIndex].focus()
              }
            }

            if (event.keyCode == 38) { // arrow up
              event.preventDefault();
              if (currentTabIndex > 0) {
                  window.currentTabIndex = window.currentTabIndex - 1;
              }
              if (window.tabbables[window.currentTabIndex]) {
                window.tabbables[window.currentTabIndex].focus()
              }
            }

            if (event.keyCode == 13) { // enter
              event.preventDefault();

              if (window.currentTabIndex > -1) {
                  if (window.tabbables[window.currentTabIndex] && 
                      window.tabbables[window.currentTabIndex].tagName != 'TEXTAREA' &&
                      window.tabbables[window.currentTabIndex].tagName != 'BUTTON') {
                        window.tabbables[window.currentTabIndex].click()
                        window.currentTabIndex = window.currentTabIndex + 1
                  }
              }
            }        

            if (event.keyCode == 13) { // enter
              if (self.currentPage == 'start' && !this.form.settings.password_protection) {
                  self.currentPage = 0
              }
            }
          })
      }, 100)
    }

    setTimeout(function(){ 
      
      var parent = document.createElement("div")
      parent.setAttribute("style", "width:30px;height:30px;")
      parent.classList.add('scrollbar-test')

      var child = document.createElement("div")
      child.setAttribute("style", "width:100%;height:40px")
      parent.appendChild(child)
      document.body.appendChild(parent)
      
      var scrollbarWidth = 30 - parent.firstChild.clientWidth
      if (scrollbarWidth) {
        document.body.classList.add("layout-scrollbar-obtrusive")
      }
      document.body.removeChild(parent)

    }, 500)

    if (urlParams.get('screenPreview') == 'main') {
      setTimeout(function(){ 
        document.getElementById('progress-bar').style.display = 'none'
        document.getElementById('footer').style.display = 'none'
        document.getElementById('top-nav').style.display = 'none'
        document.getElementById('active-container').getElementsByClassName('components-container')[0].style.minHeight = '100vh'
      }, 300)
    } else if (urlParams.get('screenPreview') == 'thumb') {
      this.currentPage = 0
      setTimeout(function(){ 
        document.getElementById('progress-bar').style.display = 'none'
        document.getElementById('footer').style.display = 'none'
        document.getElementById('top-nav').style.display = 'none'
        document.getElementById('active-container').getElementsByClassName('components-container')[0].style.maxWidth = '500px'
        document.getElementById('active-container').getElementsByClassName('components-container')[0].style.minHeight = '100vh'
        document.getElementById('active-container').style.transform = 'scale(1.7)'
      }, 300)
    }
  },
  methods: {
    formatTime (numberOfSeconds) {
      let language = this.form.settings.language

      const t = timeTranslation[language] || timeTranslation['en']
      if (!t) {
        return ""
      }

      if (language == 'pl') {
        if (numberOfSeconds <= 30) {
          return t.timeToComplete + ' ' + t.lessThan30Seconds
        } else if (numberOfSeconds < 60) {
          return t.timeToComplete + ' ' + t.lessThan1Minute
        } else {
          let minutes = Math.floor(numberOfSeconds / 60)
          if (minutes === 1) {
            return t.timeToComplete + ' 1 minuta'
          } else if (minutes % 10 >= 2 && minutes % 10 <= 4 && (minutes % 100 < 10 || minutes % 100 >= 20)) {
            return t.timeToComplete + ' ' + minutes + ' minuty'
          } else {
            return t.timeToComplete + ' ' + minutes + ' minut'
          }
        }
      }

      if (numberOfSeconds <= 30) {
        return t.timeToComplete + ' ' + t.lessThan30Seconds
      } else if (numberOfSeconds < 60) {
        return t.timeToComplete + ' ' + t.lessThan1Minute
      } else {
        const minutes = Math.floor(numberOfSeconds / 60)
        return t.timeToComplete + ' ' + minutes + ' ' + (minutes === 1 ? t.minute : t.minutes)
      }
    },
    changeLanguage () {
      let url = new URL(window.location.href)
      if (url.href.includes('lang')) {
        url.searchParams.set('lang', this.form.settings.language)
      } else {
        url.searchParams.append('lang', this.form.settings.language)
      }
      window.location.href = url
    },
    onBeforeUnload: function (e) {
      if (Number.isInteger(this.currentPage) && this.currentPage !== (this.form.components.length + 1)) {
        e.preventDefault()
        e.returnValue = ''
      }
    },
    startSurvey: function (e) {
      if (this.form.settings.password_protection) {
        if (this.respondentPassword == this.form.settings.password_protection) {
          this.currentPage = 0
        } else {

          if (!e.currentTarget.classList.contains('shake') && !e.currentTarget.classList.contains('animated')) {
            e.currentTarget.className += " shake animated";
            setTimeout(function(){
              e.target.classList.remove("animated");
              e.target.classList.remove("shake");
            }, 1000);
          }
        }
      } else {
        this.currentPage = 0
      }

      // filling time limiter
      if (this.form.settings.response_time_limit) {
            this.timeCounter = this.form.settings.response_time_limit;
            let timeArrary = this.timeCounter.split(':')
            var duration = (timeArrary[0] * 3600) + (timeArrary[1] * 60)
            let self = this
            var timer = duration, minutes, seconds
            var refreshIntervalId = setInterval(function () {
              minutes = parseInt(timer / 60, 10)
              seconds = parseInt(timer % 60, 10);

              minutes = minutes < 10 ? "0" + minutes : minutes;
              seconds = seconds < 10 ? "0" + seconds : seconds;

              self.timeCounterTxt = minutes + ":" + seconds;

              if (--timer < 0) {
                  clearInterval(refreshIntervalId)
                  document.getElementById('indicator').style.display = 'block'
                  setTimeout(function(){
                    self.submitSurvey()
                  }, 700)
              }
            }, 1000);
      }

    },
    getWindowWidth: function (event) {
        this.viewportWidth = document.documentElement.clientWidth;
    },
    validatePage: function () {
      this.pageErrors = []
      let index = this.currentPage
      if (this.form.components[index]) {
        
        if (this.form.components[index].type == 'group') {
          
          if (this.form.components[index].components && this.form.components[index].components.length > 0) {
            
            for (let i = 0; i < this.form.components[index].components.length; i++) {
              if (!this.validateInput(this.form.components[index].components[i], this.store.answers)) {
                this.pageErrors.push(this.form.components[index].components[i].ref)
                Vue.set(this.form.components[index].components[i], 'required_show_label', 1)
              }
            }
          }
        } else {

          if (!this.validateInput(this.form.components[index], this.store.answers)) {
            this.pageErrors.push(this.form.components[index].ref)
            Vue.set(this.form.components[index], 'required_show_label', 1) 
          }
        }
        
        if (this.pageErrors.length == 0) {
          this.btnNextLabel = window.form.messages.next
          this.showErrorMsg = false
        } else {
          this.showErrorMsg = true
        }
      }
      
    },
    showNextButton: function () {
      if (this.showErrorMsg) {
        this.validatePage()
      }
      this.oneClickSubmit()
    },
    partialSubmit: function () {
      let self = this
      let answers = [] 
      for (let key in self.store.answers) {
        answers.push(self.store.answers[key])      
      }

      let hidden = [] 
      for (let key in self.store.hidden) {
        hidden.push(self.store.hidden[key])      
      }

      let variables = [] 
      for (let key in self.store.variables) {
        variables.push({name: key, value: self.store.variables[key]})      
      }

      let config = {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
        withCredentials: true,
        credentials: 'same-origin',
      }

      axios.post(window.update_answer_url, {
        answers: answers,
        hidden: hidden,
        variables: variables,
        startTime: self.form.start_date.date,
        preview: self.isPreview,
        page: self.currentPage
      },config).then(function (response) {
        if (response.data) {
          if (response.data.quotaExceeded) {
            let index = self.currentPage
            if (response.data.redirectTo.type == 'end_page') {
                self.endPageRef = response.data.redirectTo.value
                self.submitSurvey(true)
            } else {
              let getJumpToIndex = self.getJumpIndexFromRef(response.data.redirectTo.value)
              if (getJumpToIndex) {
                self.currentPage = getJumpToIndex
              } else {
                self.currentPage = index + 1
              }
              
              if (self.currentPage < index) {
                let jumpIndex = _.indexOf(self.visitedPages, self.currentPage)
                self.visitedPages = self.visitedPages.slice(0, jumpIndex)
              }
            }
            self.disableUserAction = false
              
          }
        }
      })

  
    },
    submitSurvey: function (skipSave = false) {

      if (this.isSubmitted == true) {
        return false
      }
      this.isSubmitted = true

      let self = this
      document.getElementById('indicator').style.display = 'block'
      self.currentPage = self.form.components.length + 1

      if (skipSave) {
        document.getElementById('indicator').style.display = 'none'
          self.indicatorOverlay = true
          self.disableUserAction = false
        return false
      }
      setTimeout(function () { // this is not cool, but it's for waiting for variables to calculate before sent - fix it in future
     
        let answers = [] 
        for (let key in self.store.answers) {
          
          // do that in backend
          if (self.store.answers[key].type == 'choices') {
            for (let i = 0; i < self.store.answers[key].choices.length; i++) {
              delete self.store.answers[key].choices[i].ref
            }
          }

          answers.push(self.store.answers[key])      
        }

        let hidden = [] 
        for (let key in self.store.hidden) {
          hidden.push(self.store.hidden[key])      
        }

        let variables = [] 
        for (let key in self.store.variables) {
          variables.push({name: key, value: self.store.variables[key]})      
        }

        // let crsf = document.querySelector('meta[name="csrf-token"]').content

        let config = {
          method: 'POST',
          mode: 'no-cors',
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            // 'X-CSRF-TOKEN': crsf,
          },
          withCredentials: true,
          credentials: 'same-origin',
        }

        axios.post(window.save_answer_url, {
          answers: answers,
          hidden: hidden,
          variables: variables,
          startTime: self.form.start_date.date,
          preview: self.isPreview
        },config).then(function (response) {

          if (response.data.token) {
            self.responseToken = response.data.token
          }

          document.getElementById('indicator').style.display = 'none'
          self.indicatorOverlay = true
          self.disableUserAction = false
          
          parent.postMessage("close-form","*");

          if (self.endPage.properties.automatic_redirect && self.endPage.properties.automatic_redirect_url) {
            self.redirectAfterSubmitUrl = self.applyVariables(self.endPage.properties.automatic_redirect_url)
          }
          if (self.form.settings.redirect_after_submit_url) { // legacy
            self.redirectAfterSubmitUrl = self.form.settings.redirect_after_submit_url
          }

          if (self.redirectAfterSubmitUrl) {
            let metaRedirect = document.createElement('meta')
            metaRedirect.setAttribute('http-equiv', 'refresh')
            metaRedirect.setAttribute('content', '3;url=' + self.redirectAfterSubmitUrl)
            document.head.appendChild(metaRedirect)
          }

        }).catch(function (error) {})

      }.bind(this), 200)

    },


    goToNextPage: function () {
      let index = this.currentPage

      // compute conditions on page
      if (index != 'start' && this.form.components[index]) {
        if (this.form.components[index].type == 'group' && this.form.components[index].components.length > 0) {
          for (let i = 0; i < this.form.components[index].components.length; i++) {
            let ref = this.form.components[index].components[i].ref
            let rules = _.find(this.form.logic, { 'type': 'component', 'ref': ref })
            this.computeConditions(rules)
          }
        } else {
          let rules = _.find(this.form.logic, { 'type': 'component', 'ref': this.form.components[index].ref })
          this.computeConditions(rules)
        }
      }
      
      // go to next page
      if (((this.currentPage + 1) == this.form.components.length) && !this.logicJumpTo) {        
        this.submitSurvey()
      } else {
        if (window.update_answer_url) {
          this.partialSubmit()
        }
        
        if (this.logicJumpTo) {
          if (this.logicJumpTo.type == 'end_page') {
            this.endPageRef = this.logicJumpTo.value
            this.submitSurvey()
          } else {
            let getJumpToIndex = this.getJumpIndexFromRef(this.logicJumpTo)
            if (getJumpToIndex) {
              this.currentPage = getJumpToIndex
            } else {
              this.currentPage = index + 1
            }
            
            if (this.currentPage < index) {
              let jumpIndex = _.indexOf(this.visitedPages, this.currentPage)
              this.visitedPages = this.visitedPages.slice(0, jumpIndex)
            }
          }
          this.disableUserAction = false
        } else {
          if (index >= this.form.components.length) {
            this.submitSurvey()
          } else {
            this.currentPage = index + 1
            this.disableUserAction = false
          }  
        }
      }
      this.logicJumpTo = false
    },
    verifyCurrentPage: _.debounce(function () {
      this.disableUserAction = true
      Event.$emit('nextButtonClick')
      this.validatePage()
      if (this.pageErrors.length == 0) {
        this.goToNextPage()
      } else {
        var options = {
          container: '#active-container',
          easing: 'ease-in',
          offset: -60,
          x: false,
          y: true
        };
        var componentId = this.pageErrors[0]

        // this.$scrollTo('#component_' + componentId, 0, options)

        let that = this
        setTimeout(function(){
          let errorMessageElement = document.getElementById('component_' + componentId).querySelectorAll(".error")

          if (errorMessageElement.length > 0 && errorMessageElement[0]) {  
              that.$scrollTo(errorMessageElement[0], 0, options)
          }
        }, 300)

        setTimeout(function(){
          let tabbables = document.getElementById('component_' + componentId).querySelectorAll(".tabable")
          if (tabbables.length > 0 && tabbables[0]) {
            tabbables[0].focus()
          }
        }, 100)

        let index = this.currentPage
        if (this.form.components[index].type == 'group') {
          let key = _.findKey(this.form.components[index].components, { 'id': this.pageErrors[0] })
          if (key) {
            Vue.set(this.form.components[index].components[key], 'is_focused', true)
          }
        }
        this.disableUserAction = false
      }
    }, 200),
    gotoPrevPage: _.debounce(function () {
      this.disableUserAction = true
      if (this.currentPage == 0) {
        this.visitedPages = ['start']
        this.currentPage = 'start'
      }
      if (this.currentPage > 0) {
        this.visitedPages = _.dropRight(this.visitedPages, 1)
        this.currentPage = _.last(this.visitedPages)
      }
      this.disableUserAction = false
    }, 200),
    oneClickSubmit: function () {
      this.oneClickSkip = true
      let allQuestionsCompleted = false
      let rightQuestionsType = false
      let index = this.currentPage

      if (this.form.components[index]) {
        if (this.store.answers[this.form.components[index].ref]) {
          allQuestionsCompleted = true
        }
        if (
          (this.form.components[index].type == 'multiple_choice' &&
          !this.form.components[index].properties.allow_multiple_selection &&
          !this.form.components[index].properties.allow_other_choice) ||
          this.form.components[index].type == 'rating' ||
          this.form.components[index].type == 'opinion_scale' ||
          this.form.components[index].type == 'customer_sat' || 
          this.form.components[index].type == 'dropdown' || 
          this.form.components[index].type == 'yesno' || 
          this.form.components[index].type == 'legal' || 
          (this.form.components[index].type == 'picture_choice' &&
          !this.form.components[index].properties.allow_multiple_selection)
        ) {
          rightQuestionsType = true
        } else {
          rightQuestionsType = false
        }  
      } 

      if (rightQuestionsType == true && allQuestionsCompleted == true) {
        this.oneClickSkip = true
      } else {
        this.oneClickSkip = false
      }

      if (this.form.settings.disable_oneclick) {
        this.oneClickSkip = false
      }

      if (this.oneClickSkip == true) {
        setTimeout(function () {
          this.verifyCurrentPage()
        }.bind(this), 500)
      }
    },
    activeContainer: function (count) {
        if (this.currentPage == count) {
              return 'active-container';
        }
    },
    classObject: function (count) {

        if (this.currentPage == 'start') {

              if (count != 'start') {
                return 'transform: translate(' + this.viewportWidth + 'px, 0px);';
              } else {
                return 'transform: translate(0px, 0px);';
              }
        } else {
              if (count < this.currentPage) {
                return 'opacity: 0; transform: translate(-' + this.viewportWidth + 'px, 0px);';
              }
              if (count == this.currentPage) {
                return 'opacity: 1; transform: translate(0px, 0px);';
              }
              if (count > this.currentPage) {
                return 'opacity: 0; transform: translate(' + this.viewportWidth + 'px, 0px);';
              }
        }
    },
    prepareUrl: function (url) {
      url = this.applyVariables(url)
      if (!/^https?:\/\//i.test(url)) {
        return 'http://' + url
      } else {
        return url
      }
      
    }
  }
}
</script>